import {authRole} from "../../shared/constants/AppConst";
import React from "react";

class Admin {
    static route () {
        return [
            {
                auth: authRole.admin,
                routes: [
                    {
                        path: '/sample/page-1',
                        component: React.lazy(() => import('./dashboard')),
                    },
                ],
            },
            {
                auth: authRole.admin,
                routes: [
                    {
                        path: '/admin/daftar-project/:id',
                        component: React.lazy(() => import('./daftarProject')),
                    },
                    {
                        path: '/admin/daftar-project',
                        component: React.lazy(() => import('./daftarProject')),
                    },
                ],
            },
        ];
    }
    static listRoute () {
        return [
            {
                id: 'dashboard',
                title: 'Dashboard',
                messageId: 'Dashboard',
                type: 'item',
                icon: 'dashboard',
                url: '/sample/page-1',
            },
            {
                id: 'daftar-project',
                title: 'Daftar Project',
                messageId: 'Daftar Project',
                type: 'item',
                icon: 'dashboard',
                url: '/admin/daftar-project',
            },
        ]
    }
}
export default Admin
